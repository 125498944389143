import { render, staticRenderFns } from "./column.vue?vue&type=template&id=d93ce3e6&lang=true&"
import script from "./column.vue?vue&type=script&lang=js&"
export * from "./column.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports